<template>
  <svg
    viewBox="0 0 15 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6688 1.21429V2.64286C14.6688 2.8323 14.5935 3.01398 14.4596 3.14793C14.3256 3.28189 14.1439 3.35714 13.9545 3.35714H11.1527L7.58127 17.6429H9.66877C9.85821 17.6429 10.0399 17.7181 10.1738 17.8521C10.3078 17.986 10.3831 18.1677 10.3831 18.3571V19.7857C10.3831 19.9752 10.3078 20.1568 10.1738 20.2908C10.0399 20.4247 9.85821 20.5 9.66877 20.5H1.09734C0.907902 20.5 0.726221 20.4247 0.592266 20.2908C0.458312 20.1568 0.383057 19.9752 0.383057 19.7857V18.3571C0.383057 18.1677 0.458312 17.986 0.592266 17.8521C0.726221 17.7181 0.907902 17.6429 1.09734 17.6429H3.89913L7.47056 3.35714H5.38306C5.19362 3.35714 5.01194 3.28189 4.87798 3.14793C4.74403 3.01398 4.66877 2.8323 4.66877 2.64286V1.21429C4.66877 1.02485 4.74403 0.843164 4.87798 0.709209C5.01194 0.575255 5.19362 0.5 5.38306 0.5H13.9545C14.1439 0.5 14.3256 0.575255 14.4596 0.709209C14.5935 0.843164 14.6688 1.02485 14.6688 1.21429Z"
      fill="currentColor"
    />
  </svg>
</template>
